import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { style: {"text-align":"right"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sider_menu = _resolveComponent("sider-menu")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_avatar_dropdown = _resolveComponent("avatar-dropdown")!
  const _component_select_lang = _resolveComponent("select-lang")!
  const _component_header_view = _resolveComponent("header-view")!
  const _component_multi_tab = _resolveComponent("multi-tab")!
  const _component_wrap_content = _resolveComponent("wrap-content")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_global_footer = _resolveComponent("global-footer")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_setting_drawer = _resolveComponent("setting-drawer")!
  const _component_pro_provider = _resolveComponent("pro-provider")!

  return (_openBlock(), _createBlock(_component_pro_provider, { "content-width": _ctx.contentWidth }, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout, { class: "ant-pro-basicLayout" }, {
        default: _withCtx(() => [
          (_ctx.isMobile)
            ? (_openBlock(), _createBlock(_component_a_drawer, {
                key: 0,
                bodyStyle: { padding: 0, height: '100%' },
                placement: "left",
                width: _ctx.sideWidth,
                closable: false,
                visible: !_ctx.collapsed,
                "onUpdate:visible": _ctx.updateCollapsed
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_sider_menu, {
                    style: {"height":"100%"},
                    theme: _ctx.theme,
                    layout: _ctx.layout,
                    fixed: _ctx.fixedSidebar,
                    menus: _ctx.menus,
                    "sider-width": _ctx.sideWidth,
                    "split-menus": false,
                    "collapsed-button": false,
                    collapsed: false,
                    "open-keys": _ctx.openKeys,
                    "onUpdate:openKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openKeys) = $event)),
                    "selected-keys": _ctx.selectedKeys,
                    "onUpdate:selectedKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedKeys) = $event))
                  }, null, 8, ["theme", "layout", "fixed", "menus", "sider-width", "open-keys", "selected-keys"])
                ]),
                _: 1
              }, 8, ["width", "visible", "onUpdate:visible"]))
            : (!_ctx.hasTopMenu)
              ? (_openBlock(), _createBlock(_component_sider_menu, {
                  key: 1,
                  theme: _ctx.theme,
                  layout: _ctx.layout,
                  fixed: _ctx.fixedSidebar,
                  menus: _ctx.menus,
                  "sider-width": _ctx.sideWidth,
                  "split-menus": _ctx.splitMenus,
                  "collapsed-button": !_ctx.splitMenus,
                  collapsed: _ctx.collapsed,
                  "onUpdate:collapsed": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.collapsed) = $event)),
                  "open-keys": _ctx.openKeys,
                  "onUpdate:openKeys": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.openKeys) = $event)),
                  "selected-keys": _ctx.selectedKeys,
                  "onUpdate:selectedKeys": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedKeys) = $event))
                }, null, 8, ["theme", "layout", "fixed", "menus", "sider-width", "split-menus", "collapsed-button", "collapsed", "open-keys", "selected-keys"]))
              : _createCommentVNode("", true),
          _createVNode(_component_a_layout, null, {
            default: _withCtx(() => [
              _createVNode(_component_header_view, {
                theme: _ctx.theme,
                layout: _ctx.layout,
                menus: _ctx.menus,
                "sider-width": _ctx.sideWidth,
                "has-sider-menu": _ctx.hasSideMenu,
                "fixed-header": _ctx.fixedHeader,
                "split-menus": _ctx.splitMenus,
                collapsed: _ctx.collapsed,
                "onUpdate:collapsed": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.collapsed) = $event)),
                "open-keys": _ctx.openKeys,
                "onUpdate:openKeys": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.openKeys) = $event)),
                "selected-keys": _ctx.selectedKeys,
                "onUpdate:selectedKeys": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedKeys) = $event))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_avatar_dropdown, { "current-user": _ctx.currentUser }, null, 8, ["current-user"]),
                    false
                      ? (_openBlock(), _createBlock(_component_select_lang, { key: 0 }))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["theme", "layout", "menus", "sider-width", "has-sider-menu", "fixed-header", "split-menus", "collapsed", "open-keys", "selected-keys"]),
              (_ctx.multiTab)
                ? (_openBlock(), _createBlock(_component_multi_tab, {
                    key: 0,
                    fixed: _ctx.multiTabFixed,
                    "sider-width": _ctx.sideWidth
                  }, null, 8, ["fixed", "sider-width"]))
                : _createCommentVNode("", true),
              _createVNode(_component_router_view, null, {
                default: _withCtx(({ Component }) => [
                  _createVNode(_component_wrap_content, null, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                        key: _ctx.route.meta.usePathKey ? _ctx.route.path : undefined
                      }))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }),
              _createVNode(_component_global_footer)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.env === 'development')
        ? (_openBlock(), _createBlock(_component_setting_drawer, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["content-width"]))
}